<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ml-auto"
        color="secondary"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-plus</v-icon>
        Add new
      </v-btn>
    </template>
    <v-form ref="fForm">
      <v-card>
        <v-card-title>
          <span class="text-h5">Add new quick</span>
        </v-card-title>
        <v-card-text class="text-center">
          <v-text-field
            v-model="desc"
            prepend-icon="mdi-text"
            label="Description"
          ></v-text-field>
          <v-file-input
            v-model="imageFile"
            accept="image/png"
            label="Add photo"
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="addQuick"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      dialog: false,
      desc: null,
      imageFile: null,
    };
  },
  methods: {
    ...mapActions('resources', ['addNewQuickByApi']),
    addQuick() {
      const uId = Math.floor(Date.now() / 1000);
      const fReader = new FileReader();
      fReader.readAsDataURL(this.imageFile);
      fReader.onloadend = () => (
        this.addNewQuickByApi({
          quick: {
            description: this.desc,
            uniqueId: uId,
            timestamp: uId,
          },
          file: fReader.result.split(',')[1],
        }).then(() => {
          this.desc = null;
          this.imageFile = null;
          this.dialog = false;
        }).catch(() => {
          this.dialog = false;
          this.desc = null;
          this.imageFile = null;
        })
      );
    },
  },
};
</script>
