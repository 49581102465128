<template>
  <MainLayout>
    <v-card class="ma-5">
      <v-card-title>
        <v-icon>mdi-view-list</v-icon>
        QUICK LIST
        <QuickAddModal class="ml-auto"></QuickAddModal>
      </v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">
                ID
              </th>
              <th class="text-left">
                Desc
              </th>
              <th class="text-left">
                In analyze
              </th>
              <th class="text-left">
                Analyzed
              </th>
              <th class="text-left">
                Actions
              </th>
            </tr>
            </thead>
            <tbody>
            <template v-for="quick of quicks">
              <QuickRow :quick="quick" :key="quick.id"></QuickRow>
            </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </MainLayout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import MainLayout from '../../components/Layouts/MainLayout.vue';
import QuickAddModal from '../../components/QuickAddModal.vue';

export default {
  name: 'QuicksPage',
  components: {
    MainLayout,
    QuickAddModal,
    QuickRow: () => import('../../components/QuickRow.vue'),
  },

  computed: {
    ...mapState('resources', ['quicks']),
  },

  created() {
    this.getQuicksFromApi();
  },

  methods: {
    ...mapActions('resources', ['getQuicksFromApi']),
  },
};
</script>

<style lang="scss" scoped>

</style>
